import { useState, useEffect } from 'react';

interface Message {
  sender: 'user' | 'bot';
  text: string;
  isFullyRevealed?: boolean;
}

interface ChatProps {
  isDarkMode: boolean;
}

export default function Chat({ isDarkMode }: ChatProps) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputText, setInputText] = useState('');
  const [loading, setLoading] = useState(false);
  const [streamingIndex, setStreamingIndex] = useState(-1);
  const [revealedWords, setRevealedWords] = useState<string[]>([]);

  // Define API URL with fallback
  const API_URL = import.meta.env.VITE_API_URL || 'https://speakwithjesus-org.onrender.com';

  const formatText = (text: string) => {
    // Add line break before text but keep * markers
    return text.replace(/([^.])\s*\*/g, '$1\n\n');
  };

  // Function to parse and format Bible citations
  const formatBibleCitations = (text: string) => {
    // Regular expression to match common Bible citation formats
    // Examples: John 3:16, Genesis 1:1-3, Matthew 5:3-12, 1 Corinthians 13:4-7
    const citationRegex = /\b(\d\s)?[A-Za-z]+\s\d+:\d+(?:-\d+)?(?:,\s?\d+(?:-\d+)?)*\b/g;
    
    let lastIndex = 0;
    const parts: JSX.Element[] = [];
    let match;

    while ((match = citationRegex.exec(text)) !== null) {
      // Add text before the citation
      if (match.index > lastIndex) {
        parts.push(
          <span key={`text-${lastIndex}`}>
            {text.slice(lastIndex, match.index)}
          </span>
        );
      }

      // Add the citation as a link
      const citation = match[0];
      const bibleGatewayUrl = `https://www.biblegateway.com/passage/?search=${encodeURIComponent(citation)}&version=NIV`;
      parts.push(
        <a
          key={`citation-${match.index}`}
          href={bibleGatewayUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={`inline-block px-1 rounded ${
            isDarkMode 
              ? 'bg-blue-600 text-white hover:bg-blue-700' 
              : 'bg-blue-100 text-blue-800 hover:bg-blue-200'
          } transition-colors cursor-pointer`}
        >
          {citation}
        </a>
      );

      lastIndex = match.index + match[0].length;
    }

    // Add any remaining text
    if (lastIndex < text.length) {
      parts.push(
        <span key={`text-${lastIndex}`}>
          {text.slice(lastIndex)}
        </span>
      );
    }

    return parts;
  };

  useEffect(() => {
    if (streamingIndex >= 0 && !messages[streamingIndex].isFullyRevealed) {
      const words = messages[streamingIndex].text.split(' ');
      if (revealedWords.length < words.length) {
        const timer = setTimeout(() => {
          setRevealedWords(prev => [...prev, words[prev.length]]);
        }, 100); // Adjust timing as needed
        return () => clearTimeout(timer);
      } else {
        setMessages(prev => prev.map((msg, idx) => 
          idx === streamingIndex ? { ...msg, isFullyRevealed: true } : msg
        ));
        setStreamingIndex(-1);
        setRevealedWords([]);
      }
    }
  }, [streamingIndex, messages, revealedWords]);

  async function sendMessage() {
    const text = inputText.trim();
    if (!text || loading) return;
    
    setLoading(true);
    setMessages(prev => [...prev, { sender: 'user', text, isFullyRevealed: true }]);
    setInputText('');

    try {
      const res = await fetch(`${API_URL}/api/chat`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          message: text,
          conversation: messages.map(m => ({
            role: m.sender === 'user' ? 'user' : 'assistant',
            content: m.text
          }))
        })
      });
      const data = await res.json();
      const formattedReply = formatText(data.reply);
      setMessages(prev => [...prev, { sender: 'bot', text: formattedReply, isFullyRevealed: false }]);
      setStreamingIndex(messages.length + 1); // +1 because we just added two messages
    } catch (error) {
      console.error(error);
      setMessages(prev => [...prev, { sender: 'bot', text: "Error occurred.", isFullyRevealed: true }]);
    } finally {
      setLoading(false);
    }
  }

  const getDisplayText = (message: Message, index: number) => {
    if (message.sender === 'user') {
      return message.text;
    }

    const textToFormat = message.isFullyRevealed 
      ? message.text 
      : index === streamingIndex 
      ? revealedWords.join(' ')
      : message.text;

    // For streaming messages, we'll format what we have so far
    return formatBibleCitations(textToFormat);
  };

  return (
    <div className={`${
      isDarkMode ? 'bg-gray-800' : 'bg-white'
    } p-4 rounded-lg shadow-lg flex flex-col h-full`}>
      <div className="flex-grow overflow-y-auto mb-4 space-y-4">
        {messages.map((m, i) => (
          <div key={i} className={`flex ${m.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
            <div className={`rounded-lg px-4 py-2 max-w-[80%] whitespace-pre-wrap ${
              m.sender === 'user' 
                ? isDarkMode 
                  ? 'bg-green-600 text-white' 
                  : 'bg-green-100 text-gray-800'
                : isDarkMode
                  ? 'bg-gray-700 text-gray-200'
                  : 'bg-gray-200 text-gray-800'
            }`}>
              {getDisplayText(m, i)}
            </div>
          </div>
        ))}
        {loading && (
          <div className="flex justify-start">
            <div className={`rounded-lg px-4 py-2 max-w-[80%] relative overflow-hidden ${
              isDarkMode
                ? 'bg-gray-700 text-gray-200'
                : 'bg-gray-200 text-gray-800'
            }`}>
              <div className="relative z-10">
                Communicating with Jesus...
              </div>
              <div className="absolute inset-0 bg-shimmer-gradient animate-shimmer bg-[length:200%_100%]" />
            </div>
          </div>
        )}
      </div>
      <div className="flex gap-2">
        <input
          className={`border rounded-lg flex-grow px-3 py-2 ${
            isDarkMode 
              ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
              : 'bg-white border-gray-300 text-gray-800 placeholder-gray-500'
          }`}
          value={inputText}
          onChange={e => setInputText(e.target.value)}
          onKeyDown={e => e.key === 'Enter' && sendMessage()}
          placeholder="Ask a question..."
          disabled={loading}
        />
        <button
          className={`px-6 py-2 rounded-lg text-white font-medium transition-colors ${
            loading 
              ? 'bg-gray-400 cursor-not-allowed' 
              : isDarkMode
                ? 'bg-green-600 hover:bg-green-700'
                : 'bg-green-600 hover:bg-green-700'
          }`}
          onClick={sendMessage}
          disabled={loading}
        >
          {loading ? 'Sending...' : 'Send'}
        </button>
      </div>
    </div>
  );
}
