interface HeaderProps {
  isDarkMode?: boolean;
}

export default function Header({ isDarkMode }: HeaderProps) {
  return (
    <header className={`${
      isDarkMode 
        ? 'bg-gray-800 text-white border-b border-gray-700' 
        : 'bg-white text-gray-800 shadow'
    } p-4 text-center transition-colors`}>
      <h1 className="text-2xl font-bold">
        Speak With Jesus
      </h1>
    </header>
  );
}
